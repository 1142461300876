<template>
    <div class="table">

        <!-- 页面标题 start -->
        <div class="page_title">
            产品开发
        </div>
        <!-- 页面标题 end -->

        <!-- 卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '16px',
                maxWidth: '1760px',
                height: 'calc(100% - 30px)',
                margin: '0 auto'
            }"
            shadow="never">

            <!-- 筛选条件表单 start -->
            <el-form :inline="true" size="small" :model="productForm" style="margin-bottom: 6px;">
                <el-form-item>
                    <el-input v-model.trim="productForm.keyword" clearable placeholder="产品名称/PID" @clear="getMsg(1)"
                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)" :maxlength="32"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-cascader
                        clearable
                        @change="getMsg(1)"
                        :show-all-levels="false"
                        placeholder="全部品类"
                        v-model="productForm.productType"
                        :options="productTypeList"
                        :props="props">
                    </el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="productForm.communication" clearable placeholder="全部通讯方式">
                        <el-option
                            v-for="item in $message.protocolTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="productForm.state" clearable placeholder="全部状态">
                        <el-option
                            v-for="item in $message.productStatusList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--                <el-form-item>-->
                <!--                    <el-button type="primary" icon="el-icon-search" @click="getMsg(1); btnLoading = true;" :loading="btnLoading" plain>搜索</el-button>-->
                <!--                </el-form-item>-->
            </el-form>
            <!-- 筛选条件表单 end -->

            <el-divider></el-divider>

            <!-- 卡片列表 start -->
            <el-row :gutter="24" :style="{ height: tableHeight + 'px' }" class="product_list" v-loading="loading">
                <el-col :span="4" style="margin-top: 15px;">
                    <div class="product_item" style="cursor: pointer;" @click="addProduct">
                        <i class="add_product el-icon-plus"></i>
                    </div>
                </el-col>
                <el-col :span="4" style="margin-top: 15px;" v-for="(item, index) in productMsg.list" :key="index">
                    <div class="product_item">
                        <div class="product_status" :style="{
                            backgroundColor: $config.getMsgItemUtil($message.productStatusColorList,item.status,'id','name')
                        }">
                            {{ $config.getMsgItemUtil($message.productStatusList, item.status, 'id', 'name') }}
                        </div>
                        <el-image
                            style="width: 126px;height: 126px;padding: 0 5px 10px 5px"
                            :src="item.icon"
                            :preview-src-list="[item.icon]">
                            <div slot="error">
                                <div class="image_slot">
                                    没有产品拟物图
                                </div>
                            </div>
                        </el-image>
                        <el-row>
                            <el-col :span="22">
                                <div class="product_msg">
                                    <div class="product_name" :title="item.name">{{ item.name }}</div>
                                    <div class="product_pid" :title="item.pid">{{ item.pid }}</div>
                                </div>
                            </el-col>
                            <el-col :span="2" style="text-align: left;">
                                <el-dropdown
                                    trigger="click"
                                    size="small"
                                    placement="bottom-end"
                                    @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        <i class="product_gd_icon icon-gengduo"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="beforeHandleCommand(0, item)">编辑</el-dropdown-item>
                                        <el-dropdown-item :command="beforeHandleCommand(1, item)">复制</el-dropdown-item>
                                        <el-dropdown-item v-if="item.status != 3"
                                                          :command="beforeHandleCommand(2, item)">删除
                                        </el-dropdown-item>
                                        <el-dropdown-item :command="beforeHandleCommand(3, item)"
                                                          v-else>设备管理
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <!-- 卡片列表 end -->

            <!-- 分页器 start -->
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="11"
                layout="prev, pager, next"
                style="float: right;margin-top: -10px;"
                :current-page="page"
                :total="parseInt(productMsg.totalRecords)">
            </el-pagination>
            <!-- 分页器 end -->

        </el-card>
        <!-- 卡片 end -->

    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    data() {

        return {

            loading: false,

            btnLoading: false,

            //表格高度
            tableHeight: 50,

            //查询表单
            productForm: {
                //产品名称/PID
                keyword: "",
                //品类code
                productType: "",
                //通讯方式
                communication: "",
                //产品状态
                state: ""
            },

            //级联选择器配置选项
            props: {
                //开启懒加载
                lazy: true,
                //指定选项的值为选项对象的某个属性值
                value: "code",
                //指定选项标签为选项对象的某个属性值
                label: "name",
                //指定选项的子选项为选项对象的某个属性值
                children: "children",
                //加载动态数据的方法
                //node为当前点击的节点，resolve为数据加载完成的回调(必须调用)
                lazyLoad: (node, resolve) => {
                    console.log(node);
                    if (!node.value) {
                        //获取一级品类
                        this.getProtocolTypeList();
                        return
                    }
                    this.getLevelProtocolTypeList(node.value, resolve)
                }
            },

            //当前页
            page: 1,

            //品类数据
            productTypeList: [],

            //数据源
            productMsg: {
                list: [],
                totalRecords: 0
            }

        }

    },

    mounted() {
        //动态加载表格高度
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 245 - 8;
        }, 100)
        window.addEventListener('resize', this.setHeight);

        //获取数据
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight)
    },

    methods: {

        ...mapActions('product', ['queryProduct', 'deleteProduct']),
        ...mapActions('category', ['queryFirstLevelCategory', 'queryChildsCategory']),

        /**
         * 高度适配
         */
        setHeight() {
            this.tableHeight = window.innerHeight - 245 - 8;
        },

        /**
         * 点击操作省略号后获得行号与行数据
         * @param index 行号
         * @param row 行数据
         * @returns {{index, row}}
         */
        beforeHandleCommand(index, row) {
            return {
                index: index,
                row: row,
            };
        },

        /**
         * 点击省略号回调
         * @param command
         */
        handleCommand(command) {
            console.log(command)
            switch (command.index) {
                //编辑产品
                case 0:
                    this.modify(command.row);
                    break;
                //复制产品
                case 1:
                    this.copy(command.row);
                    break;
                //删除产品
                case 2:
                    this.delProduct(command.row);
                    break;
                //设备管理
                case 3:
                    this.$router.push({path: "/deviceManagement", query: {pid: command.row.pid}});
                    break;
            }
        },

        /**
         * 获取产品列表
         * @param page
         */
        getMsg(page) {
            if (page) this.page = page;
            this.loading = true;
            this.queryProduct({
                categoryCode: this.productForm.productType[2],
                currPage: this.page,
                pageSize: 11,
                keyword: this.productForm.keyword,
                productStatus: this.productForm.state,
                protocolType: this.productForm.communication,
            }).then(res => {
                this.productMsg = res.result
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.btnLoading = false;
                this.loading = false;
            })
        },

        /**
         * 获取产品类型列表
         */
        getProtocolTypeList() {
            this.queryFirstLevelCategory().then(res => {
                if (res.result.length == 0) return
                res.result.forEach(item => {
                    item.name = JSON.parse(item.name).zh;
                    item.leaf = false;
                })
                this.productTypeList = res.result
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },

        /**
         * 获取下级产品类型列表
         * @param code
         * @param resolve
         */
        getLevelProtocolTypeList(code, resolve) {
            this.queryChildsCategory({
                code
            }).then(res => {
                res.result.forEach(item => {
                    item.name = JSON.parse(item.name).zh;
                    if (item.level == 2) {
                        item.children = [];
                        item.leaf = false;
                    }
                    if (item.level == 3) {
                        item.leaf = true;
                    }
                })
                console.log(res.result)
                resolve(res.result)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 复制产品
         * @param row
         */
        copy(row) {
            this.$store.commit("main/SET_COPY_PRODUCT_DIALOG_VISIBLE", true);
            this.$store.commit("main/SET_PRODUCTITEM_ITEM_MSG", row);
        },

        /**
         * 分页点击回调
         * @param page
         */
        handleCurrentChange(page) {
            this.page = page;
            this.getMsg()
        },

        /**
         * 删除产品
         * @param row
         */
        delProduct(row) {
            this.$dialog.showConfirm({
                content: "是否删除该产品？",
                title: "提示",
                btn1: "删除",
                btn2: "取消",
                icon: 2
            }, () => {
                this.loading = true;
                this.deleteProduct({
                    productCode: row.code
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.loading = false;
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        /**
         * 编辑产品
         * @param row
         */
        modify(row) {
            this.$router.push({path: "/product", query: {id: row.code}});
        },

        /**
         * 添加产品
         */
        addProduct() {
            this.$router.push({path: "/addProduct"});
        }

    }

}
</script>

<style scoped>
.el-card {
    width: calc(100% - 48px);
    height: 100%;
    border: none;
    border-radius: 8px;
    margin: 64px 24px 24px 24px;
}

.table {
    width: 100%;
    height: calc(100% - 75px - 8px);
}

/deep/ .el-card__header {
    padding: 10px 10px 10px 15px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    border: none;
}

/deep/ .el-divider--horizontal {
    width: calc(100% + 30px) !important;
    margin: 0 -15px 0 -15px !important;
}

.el-divider {
    background-color: #E9E9E9 !important;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}

.product_list {
    width: calc(100% + 15px);
    overflow-y: auto;
    padding-bottom: 10px;
}

.el-form-item {
    margin-bottom: 10px;
}

.el-input {
    width: 200px;
}

.el-select {
    width: 150px;
}

.add_product {
    line-height: 205px;
    font-size: 50px;
}

.product_item {
    position: relative;
    width: 100%;
    height: 205px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    margin-top: 6px;
    text-align: center;
}

.product_msg {
    height: 50px;
    background: #F7F7F7;
    border-radius: 8px;
    margin: 5px 10px 10px 10px;
}

.product_gd_icon {
    font-family: "iconfont" !important;
    font-size: 20px;
    color: #555555;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 20px;
    margin-left: -40%;
    display: block;
    cursor: pointer;
}

.product_name, .product_pid {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product_name {
    padding-top: 10px;
}

.product_pid {
    font-size: 10px;
    margin-top: 5px;
}

.product_status {
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    height: 24px;
    border-radius: 0 8px 0 8px;
    z-index: 99;
    color: white;
    font-weight: 400;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
}

.image_slot {
    background-color: #F7F7F7;
    width: 126px;
    height: 126px;
    font-size: 12px;
    line-height: 126px;
    color: #c0c4cc;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    background: #00000000;
    border-radius: 10px;
    margin-top: 10px;
}


</style>
