<template>
    <div>
        <div class="page_title">
            个人信息
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '20px',
                maxWidth: '1760px',
                maxHeight: '720px',
                margin: '0 auto',
                height: height + 'px'
            }"
            shadow="never">

            <el-row style="height: 100%;background: #F7F7F7;padding: 20px">

                <el-col :span="10" style="height: 100%;">
                    <el-upload
                        class="avatar-uploader"
                        :action="$message.uploadImgUrl"
                        :data="{
                           bucket: 'enterprise'
                        }"
                        :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken
                        }"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :on-error="onErr"
                        drag
                        :loading="loadAvatar"
                        accept=".png,.jpeg,.jpg,.gif,.bmp"
                        :before-upload="beforeAvatarUpload">

                        <el-avatar :size="106" v-if="userMsg.headImg" >
                            <img :src="userMsg.headImg"  @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;"/>
                            <div class="avatar-mask" v-show="isShowUpload" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;">
                                <i class="el-icon-upload2 icon-upload"></i>
                            </div>
                        </el-avatar>
                        <el-avatar :size="106" style="background: transparent;" v-else>
                            <img src="../../../../assets/icon_avatar.png" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;" />
                            <div class="avatar-mask" v-show="isShowUpload" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;">
                                <i class="el-icon-upload2 icon-upload"></i>
                            </div>
                        </el-avatar>

                        <el-button size="small" icon="el-icon-upload2">上传头像</el-button>

                        <div class="notes">可以拖动图片到左边头像区域完成上传</div>

                    </el-upload>
                </el-col>
                <el-col :span="14" style="height: 100%;">

                    <div class="user_item">
                        <img src="../../../../assets/icon_nickname.png" class="icon-item">
                        <div class="user_item_title">昵称</div>
                        <div class="user_item_value" v-if="isEditNickname">
                            <el-input
                                size="mini"
                                maxlength="20"
                                placeholder="请输入昵称"
                                v-model="userMsg.nickname">
                            </el-input>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px ;" :loading="loadEditNickname" @click="updateMsg('nickname')">确定</el-button>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" @click="isEditNickname = false;userMsg.nickname = userMsgBackups.nickname;">取消</el-button>
                        </div>
                        <div class="user_item_value" v-else>{{userMsg.nickname}}</div>

                        <el-button type="text" class="user_item_btn" @click="isEditNickname = true" v-if="!isEditNickname">修改昵称</el-button>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_user_id.png" class="icon-item">
                        <div class="user_item_title">用户ID</div>
                        <div class="user_item_value">{{userMsg.userId}}</div>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_address.png" class="icon-item">
                        <div class="user_item_title">地址</div>
                        <div class="user_item_value" v-if="isEditAddress">
                            <el-input
                                size="mini"
                                maxlength="50"
                                placeholder="请输入地址"
                                v-model="userMsg.address">
                            </el-input>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" :loading="loadEditAddress" @click="updateMsg('address')">确定</el-button>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" @click="isEditAddress = false;userMsg.address = userMsgBackups.address;">取消</el-button>
                        </div>
                        <div class="user_item_value" v-else>{{userMsg.address}}</div>
                        <el-button type="text" class="user_item_btn"  @click="isEditAddress = true" v-if="!isEditAddress">修改地址</el-button>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_phone.png" class="icon-item">
                        <div class="user_item_title">手机号</div>
                        <div class="user_item_value">{{userMsg.tel}}</div>
                        <el-button type="text" class="user_item_btn" @click="updateTel">修改手机号</el-button>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_password.png" class="icon-item">
                        <div class="user_item_title">密码</div>
                        <div class="user_item_value">******</div>
                        <el-button type="text" class="user_item_btn" @click="updatePassword">修改密码</el-button>
                    </div>

                </el-col>

            </el-row>

        </el-card>

        <update-tel-dialog @getUserInfo="getUserInfo"></update-tel-dialog>
        <update-password-dialog @getUserInfo="getUserInfo"></update-password-dialog>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import updateTelDialog from '@/views/setting/views/userInfo/components/dialog/updateTelDialog';
import updatePasswordDialog from '@/views/setting/views/userInfo/components/dialog/updatePasswordDialog';


export default {

    components: {
        updateTelDialog,
        updatePasswordDialog
    },

    computed: {
        ...mapState('login', ['userInfo'])
    },

    watch: {
        userInfo: {
            handler(newVal) {
                this.userMsg = {
                    nickname: newVal.nickname,
                    userId: newVal.userId,
                    headImg: newVal.headImg,
                    address: newVal.address,
                    tel: newVal.telephone
                };
                this.userMsgBackups = this.$config.deepCopy(this.userMsg);
            }
        }
    },


    data() {
        return {
            userMsg: {
                nickname: "",
                userId: "",
                headImg: "",
                address: ""
            },
            userMsgBackups: {
                nickname: "",
                userId: "",
                headImg: "",
                address: ""
            },
            height: 300,
            loadAvatar: false,
            isEditNickname: false,
            loadEditNickname: false,
            isEditAddress: false,
            loadEditAddress: false,
            isShowUpload: false
        }
    },

    mounted() {
        setTimeout(() => {
            this.height = window.innerHeight - 190;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getUserInfo();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    methods: {

        ...mapActions('login', ['getAdminuserInfo', 'modifyUserInfo']),

        setHeight() {
            this.height = window.innerHeight - 190;
        },

        getUserInfo() {
            this.getAdminuserInfo();
        },

        updatePassword() {
            this.$store.commit("setting/SET_UPDATE_PASSWORD_DIALOG_VISIBLE", true);
        },

        updateTel() {
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_VISIBLE", true);
        },

        handleAvatarSuccess(res, file) {
            console.log(res, file)
            if (res.success) {
                this.userMsg.headImg = res.result.fileList[0].url;
                this.updateMsg("avatar");
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        updateMsg(type) {
            if (type === 'nickname' && !this.userMsg.nickname){
                this.$dialog.showMessage('请填写昵称！', this.$config.TOAST_WARNING);
                return;
            }
            if (type === 'address' && !this.userMsg.address){
                this.$dialog.showMessage('请填写地址！', this.$config.TOAST_WARNING);
                return;
            }
            if (type === 'avatar' && !this.userMsg.headImg){
                this.$dialog.showMessage('请上传头像！', this.$config.TOAST_WARNING);
                return;
            }


            type === "nickname" ? this.loadEditNickname = true : type === "address" ? this.loadEditAddress = true : this.loadAvatar = true;

            this.modifyUserInfo(this.userMsg).then(res => {
                this.getUserInfo();
                this.$dialog.showMessage('修改成功', this.$config.TOAST_SUCCESS);

                this.isEditNickname = false;
                this.isEditAddress = false;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loadAvatar = false;
                this.loadEditNickname = false;
                this.loadEditAddress = false;
            })
        },


        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isGIF = file.type === 'image/gif';
            const isBMP = file.type === 'image/bmp';

            const isSize = new Promise(function (resolve, reject) {
                let width = 200;
                let height = 200;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传头像尺寸不符合,只能是200*200!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!(isJPG || isJPEG || isPNG || isGIF || isBMP)) {
                this.$dialog.showMessage('图片仅支持jpg/jpeg/png/gif/bmp格式！', this.$config.TOAST_WARNING);
            }

            if ((isJPG || isJPEG || isPNG || isGIF || isBMP) && isSize) this.loadAvatar = true;
            return (isJPG || isJPEG || isPNG || isGIF || isBMP) && isSize;
        },

        /**
         * 图片上传失败回调
         */
        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

    }

}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
}

/deep/ .avatar-uploader {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}

/deep/ .el-upload, /deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    background: #F7F7F7;
}

/deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    text-align: center;
    border-right: 1px dashed #d9d9d9;
}

/deep/ .el-upload-dragger:hover {
    border-color: #d9d9d9;
}

.el-avatar {
    position: absolute;
    margin-top: 100px;
}

.el-upload-dragger .el-button {
    width: 106px;
    position: relative;
    margin-top: 230px;
}

.notes {
    font-weight: 400;
    font-size: 14px;
    color: rgba(138, 143, 141, 0.65);
    margin-top: 16px;
}

.user_item {
    width: 80%;
    height: 18%;
    margin-left: 10%;
    margin-top: 1.6%;
    position: relative;
    /*padding: 12px 32px;*/
    background: #FFFFFF;
    border-radius: 12px;
}

.user_item:first-child {
    margin-top: 0;
}

.user_item_title {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.90);
    position: absolute;
    top: 12px;
    left: 92px;
}

.user_item_value {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.45);
    position: absolute;
    bottom: 12px;
    left: 92px;
    display: flex;
}

.user_item_btn {
    position: absolute;
    right: 32px;
    top: 32%;
}

.icon-item {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 30px;
}

.avatar-mask {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.60);
    position: relative;
    top: -100%;
}

.icon-upload {
    font-size: 24px;
    text-align: center;
    line-height: 106px;
}

</style>
