<template>
    <el-drawer
        title="新建产品"
        direction="rtl"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog">

        <el-divider></el-divider>

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item label="产品名称：" prop="productName">
                <el-input
                    type="text"
                    v-model.trim="form.productName"
                    placeholder="20个字符内">
                </el-input>
            </el-form-item>
            <el-form-item label="产品类别：" :error="err.productTypeErr" required>
                <el-button type="primary" @click="selectProductType" v-if="JSON.stringify(selectMsg) == '{}'">选择品类
                </el-button>
                <el-card :body-style="{ padding: '0px' }" class="product_card" v-else>
                    <el-button type="text" icon="el-icon-close" class="clean_btn" @click="cleanProduct()"></el-button>
                    <img :src="selectMsg.iconUrl" class="image" width="100" height="100">
                    <div class="card_title">{{ JSON.parse(selectMsg.name).zh }}</div>
                </el-card>
            </el-form-item>
            <el-form-item label="产品型号：" prop="productModel">
                <el-input
                    type="text"
                    v-model.trim="form.productModel"
                    placeholder="请输入产品型号，仅支持小写字母或数字">
                </el-input>
            </el-form-item>
            <el-form-item label="通讯方式：" prop="communication">
                <el-select v-model="form.communication" :popper-append-to-body="false" placeholder="选择通讯方式">
                    <el-option
                        v-for="item in communicationList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品拟物图：">
                <el-upload
                    class="avatar-uploader"
                    :action="$message.uploadImgUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :on-error="onErr"
                    accept=".png"
                    :data="$message.data"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="form.file" :src="form.file" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="备注：">
                <el-input
                    type="textarea"
                    :rows="4"
                    maxlength="200"
                    placeholder="请输入备注"
                    show-word-limit
                    v-model="form.remark">
                </el-input>
            </el-form-item>
        </el-form>

        <div class="demo-drawer__footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
        </div>

        <!-- 新增产品类型抽屉 -->
        <add-product-type-drawer></add-product-type-drawer>

    </el-drawer>
</template>

<script>
import {mapState, mapActions} from "vuex";
import addProductTypeDrawer from '@/views/product/views/basic/components/addProductTypeDrawer';

export default {

    components: {addProductTypeDrawer},

    computed: {
        ...mapState('main', ['addProductDialogVisible']),
        ...mapState('product', ['selectCategoryMsg'])
    },

    watch: {

        addProductDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        selectCategoryMsg: {
            handler(newVal) {
                this.selectMsg = newVal;
                if (this.selectMsg.code) {
                    this.err.productTypeErr = ""
                }
            },
            deep: true
        }

    },

    data() {

        return {

            file: {},

            formLabelWidth: "100px",

            loading: false,

            loadImg: false,

            err: {
                productTypeErr: ""
            },

            form: {
                productName: "",
                productType: "",
                productModel: "",
                communication: "",
                file: "",
                remark: ""
            },

            productTypeList: [
                {id: 1, name: "水杯"},
                {id: 2, name: "炒菜机"},
                {id: 3, name: "摄像头"}
            ],

            communicationList: [],

            selectMsg: {},

            visible: false,

            rules: {
                productName: [
                    {required: true, message: "请输入产品名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                productType: [{required: true, message: "请选择产品类别", trigger: "blur"}],
                communication: [{required: true, message: "请选择通讯方式", trigger: "blur"}],
                productModel: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-z_0-9]*$/, message: '只能输入小写字母、下划线及数字'},
                ]
            }

        }

    },

    methods: {

        /**
         * vuex方法
         */
        ...mapActions('main', ['closeAddProductDialog']),
        ...mapActions('product', ['addProduct']),

        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.form.file = res.result.filePath;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        beforeAvatarUpload(file) {
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 < 100;
            const isSize = new Promise(function (resolve, reject) {
                let width = 800;
                let height = 800;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传图片尺寸不符合,只能是800*800!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!isPNG) {
                this.$dialog.showMessage('产品拟物图仅支持PNG格式！', this.$config.TOAST_WARNING);
            }
            if (!isLt2M) {
                this.$dialog.showMessage('上传图片大小不能超过 100KB！', this.$config.TOAST_WARNING);
            }
            return isPNG && isLt2M && isSize;
        },

        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.closeAddProductDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                productName: "",
                productType: "",
                productModel: "",
                communication: "",
                file: "",
                remark: ""
            };
            this.selectMsg = {};
            this.cleanErr();
        },

        /**
         * 打开弹窗回调
         */
        openDialog() {
            this.communicationList = this.$message.protocolTypeList;
        },

        /**
         * 删除（清空）上传文件
         */
        delBtn() {
            this.file = {}
        },

        /**
         * 打开品类选择侧边栏
         */
        selectProductType() {
            this.$store.commit("product/SET_ADD_PRODUCT_TYPE_DRAWER_VISIBLE", true);
        },

        /**
         * 清除所选品类
         */
        cleanProduct() {
            this.selectMsg = {};
            this.$store.commit("product/SET_SELECT_CATEGORY_MSG", {});
        },

        /**
         * 清除错误提示
         */
        cleanErr() {
            this.err = {
                productTypeErr: ""
            }
        },

        /**
         * 提交新增产品表单
         */
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (!this.selectMsg.code) {
                    this.err.productTypeErr = "请选择品类"
                    return;
                }
                this.cleanErr();
                if (valid) {
                    this.loading = true;
                    this.addProduct({
                        categoryCode: this.selectMsg.code,
                        icon: this.form.file,
                        model: this.form.productModel,
                        name: this.form.productName,
                        protocolType: this.form.communication,
                        remark: this.form.remark,
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage("添加成功", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getMsg', 1);
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        }

    }

}
</script>

<style scoped>
.el-select {
    width: 100%;
}

.tips {
    font-size: 13px;
    color: rgb(215, 215, 215);
}

/deep/ .el-drawer {
    text-align: left;
}

/deep/ .el-divider--horizontal {
    margin-top: 0px;
}

/deep/ .el-drawer__header {
    margin-bottom: 20px;
}

/deep/ .el-form {
    padding: 0 15px;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;
    width: 35%;
    text-align: right;
    background-color: white;
}

.product_card {
    width: 100px;
    text-align: center;
}

.card_title {
    line-height: 10px;
    padding-bottom: 15px;
}

.clean_btn {
    margin: 5px 5px -10px 0;
    padding: 0 !important;
    float: right;
    position: relative;
    z-index: 999;
}

.avatar {
    width: 80px;
    height: 80px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
</style>
