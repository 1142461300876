<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="确认产品信息"
        :visible.sync="visible"
        width="35%"
        top="5vh"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="release_form"
                 :label-width="formLabelWidth">
            <el-form-item label="产品名称：">
                {{ form.productName }}
            </el-form-item>
            <el-form-item label="pid：">
                {{ form.pid }}
            </el-form-item>
            <el-form-item label="产品类别：">
                <el-tag type="success" size="small" v-for="(item,index) in form.productType" :key="index" style="">
                    {{ $config.getMsgItemUtil(productTypeList, item, 'id', 'name') }}
                </el-tag>
            </el-form-item>
            <el-form-item label="产品型号：" prop="productClass">
                <el-input
                    type="text"
                    v-model.trim="form.productClass"
                    placeholder="">
                </el-input>
            </el-form-item>

            <el-form-item label="产品拟物图：" required>
                <el-upload
                    class="upload"
                    v-loading="loadImg"
                    drag
                    accept=".png,.jpg"
                    :on-success="onSuccess"
                    :on-error="onError"
                    :on-progress="onProgress"
                    :before-upload="onUpdateBefor"
                    :show-file-list="false"
                    action="https://jsonplaceholder.typicode.com/posts/">
                    <div v-if="!file.name">
                        <i class="el-icon-upload" style="margin-top: 40px"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </div>
                    <div style="margin-top: 120px;font-size: 20px;color: #0AADC1;" v-else>
                        <span>{{ file.name }}</span>
                        <el-button type="text" style="padding: 0px;"
                                   icon="el-icon-delete" @click.stop="delBtn()"></el-button>
                    </div>
                </el-upload>
                <span class="tips">168*168像素，png格式，透明底，100KB以内</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    computed: {
        ...mapState('release', ['releaseDialogVisible'])
    },
    watch: {
        releaseDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },
    data() {
        return {
            visible: false,
            loadImg: false,
            loading: false,
            file: {},
            formLabelWidth: "120px",
            form: {
                productName: "爱因斯坦的火箭",
                pid: "lango.camera.123",
                productType: [1, 2],
                productClass: "",
            },
            rules: {
                productClass: [{required: true, message: "请输入产品型号", trigger: "blur"}],
            },
            productTypeList: [
                {id: 1, name: "水杯"},
                {id: 2, name: "炒菜机"},
                {id: 3, name: "摄像头"}
            ],
        }
    },
    methods: {
        closeDialog() {
            this.closeReleaseDialog();
            this.$refs.ruleForm.resetFields();
        },
        openDialog() {

        },
        ...mapActions('release', ['closeReleaseDialog']),
        onUpdateBefor(file) {
            if (this.$config.getType(file.name) == '.png' || this.$config.getType(file.name) == '.jpg') {
                this.loadImg = true;
            } else {
                this.$dialog.showMessage('只能上传.png.jpg', this.$config.TOAST_WARNING);
                return false
            }
        },
        onProgress(event, file, fileList) {
            console.log('onProgress', event, file, fileList)
        },
        onSuccess(response, file, fileList) {
            this.loadImg = false;
            if (response.success) {
                this.$dialog.showMessage('文件上传成功', this.$config.TOAST_SUCCESS);
                this.file = file;
                this.file.url = response.result.fileList[0].url
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
            console.log('onSuccess', response, file, fileList)
        },
        onError(err, file, fileList) {
            this.loadImg = false;
            this.$dialog.showMessage('文件上传失败', this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },
        delBtn() {
            this.file = {}
        },
    },
}
</script>

<style scoped>
.el-tag {
    margin-left: 10px;
}

.el-tag:first-child {
    margin-left: 0;
}
</style>
